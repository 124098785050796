exports.pointGroups = [
   {
      name: "Can nhiệt",
      points: [
         {
            reportName: 'temp01', name: 'Can nhiệt 1', active: false,
            scale: 1, unit: '°C', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp02', name: 'Can nhiệt 2', active: false,
            scale: 1, unit: '°C', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp03', name: 'Can nhiệt 3', active: false,
            scale: 1, unit: '°C', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp04', name: 'Can nhiệt 4', active: false,
            scale: 1, unit: '°C', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp05', name: 'Can nhiệt 5', active: false,
            scale: 1, unit: '°C', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp06', name: 'Khí nóng', active: false,
            scale: 1, unit: '°C', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'temp07', name: 'Khí ẩm', active: false,
            scale: 1, unit: '°C', color: "#FF0000", backgroundColor: "#FF000000", 
         },
      ]
   },
   {
      name: "Biến tần",
      points: [
         {
            reportName: 'fan1_frequency', name: 'Tốc độ biến tần thải sấy', active: false,
            scale: 1, unit: 'Hz', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'fan3_frequency', name: 'Tốc độ biến tần thu hồi', active: false,
            scale: 1, unit: 'Hz', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'fan2_frequency', name: 'Tốc độ biến tần thải nung', active: false,
            scale: 1, unit: 'Hz', color: "#FF0000", backgroundColor: "#FF000000", 
         },
      ]
   },
   {
      name: "Xe gạch",
      points: [
         {
            reportName: 'accuCounterIn', name: 'Xe vào lò nung', accu: true, active: false,
            scale: 1, unit: 'xe', color: "#FF0000", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'accuCounterOut', name: 'Xe ra lò sấy', accu: true, active: false,
            scale: 1, unit: 'xe', color: "#FF0000", backgroundColor: "#FF000000", 
         },
      ]
   },
   {
      name: "Điện năng",
      points: [
         {
            reportName: 'current_A', name: 'Dòng điện pha A', accu: false, active: false,
            scale: 1, unit: 'A', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'current_B', name: 'Dòng điện pha B', accu: false, active: false,
            scale: 1, unit: 'A', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'current_C', name: 'Dòng điện pha C', accu: false, active: false,
            scale: 1, unit: 'A', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'voltage_A', name: 'Điện áp pha A', accu: false, active: false,
            scale: 1, unit: 'V', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'voltage_B', name: 'Điện áp pha B', accu: false, active: false,
            scale: 1, unit: 'V', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'voltage_C', name: 'Điện áp pha C', accu: false, active: false,
            scale: 1, unit: 'V', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'power_A', name: 'Công suất pha A', accu: false, active: false,
            scale: 1, unit: 'W', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'power_B', name: 'Công suất pha B', accu: false, active: false,
            scale: 1, unit: 'W', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'power_C', name: 'Công suất pha C', accu: false, active: false,
            scale: 1, unit: 'W', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'copshi', name: 'Cosphi', accu: false, active: false,
            scale: 1, unit: '', color: "#009688", backgroundColor: "#FF000000", 
         },
         // {
         //    reportName: 'copshi_B', name: 'Cosphi_B', accu: false, active: false,
         //    scale: 1, unit: '', color: "#009688", backgroundColor: "#FF000000", 
         // },
         // {
         //    reportName: 'copshi_C', name: 'Cosphi_C', accu: false, active: false,
         //    scale: 1, unit: '', color: "#009688", backgroundColor: "#FF000000", 
         // },
         {
            reportName: 'frequency', name: 'Tần số', accu: false, active: false,
            scale: 1, unit: '', color: "#009688", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'accuEnergy', name: 'Điện năng tiêu thụ', accu: true, active: false,
            scale: 1, unit: 'kWh', color: "#009688", backgroundColor: "#FF000000", 
         },
      ]
   },
]